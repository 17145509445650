<template>
  <div class="bg">
    <header class="header">
      <nav class="navbar navbar-expand-lg navbar-light py-3">
        <div class="container">
          <!-- Navbar Brand -->
          <a
            href="https://moonfroglabs.com/"
            target="_blank"
            class="navbar-brand"
          >
            <img src="../assets/moonfrog-logo.png" alt="logo" width="150" />
          </a>
          <div class="reseller-name">
            <p class="reseller-name-text">{{ name }}</p>
          </div>
        </div>
      </nav>
    </header>
    <div class="main-content">
      <div class="game-table">
        <a
          class="game-box"
          v-for="game in games"
          v-bind:key="game"
          @click="downloadGame(game)"
        >
          <!-- :href="game.referralLink" -->
          <img :src="game.img" />
          <i class="fa fa-arrow-down"></i>
          <h2>{{ game.title }}</h2>
          <p>About: {{ game.about }}</p>
        </a>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import "@/assets/css/referral.scss";
import * as client from "@/client/webclient";

export default {
  data() {
    return {
      email: this.$route.params.email,
      name: "",
      isLoading: true,
      referralData: null,
      games: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      /*
          export interface SageReferralResponse {
            title: string,
            about: string,
            img: string,
            referralLink: string,
          }
       */
      try {
        let referralData = await client.getReferralData(
          this.$route.params.email
        );
        this.games = referralData.games;
        this.name = referralData.name;
      } catch (err) {
        this.showConnectionErrorDialog(err);
      } finally {
        this.isLoading = false;
      }
    },
    async downloadGame(game) {
      window.location.href = game.referralLink;
    },
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>

<style scoped>
.game-box {
  color: black;
}

.game-box:hover {
  text-decoration: none;
}

.game-box:visited {
  color: black;
}
</style>
